import { render } from 'react-dom';
import React, { Suspense } from 'react';
import { Canvas } from 'react-three-fiber';
import { useProgress, Html } from '@react-three/drei';
import {
    HashRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
} from 'react-router-dom';

import Scene from './Scene';

import './base.css';

function Loader() {
    const { progress } = useProgress();
    return (
        <Html center>
            <span style={{ color: '#FFFFFF' }}>{progress} % loaded</span>
        </Html>
    );
}

function App(props) {
    const { scene = 1 } = props;
    return (
        <Canvas concurrent shadowMap camera={{ position: [0, 0, 5], fov: 70 }}>
            <color attach='background' args={['#000']} />
            <Suspense fallback={<Loader />}>
                {scene === 1 && <Scene />}
            </Suspense>
            <ambientLight intensity={0.4} />
        </Canvas>
    );
}

function Body() {
    return (
        <Router>
            <main>
                {/* <div className="frame">
                    <div className="frame__title-wrap">
                        <h1 className="frame__title">Title</h1>
                        <p className="frame__tagline">Tagline</p>
                    </div>
                    <div className="frame__links">
                        <a href="">Link</a>
                    </div>
                    <div className="frame__demos">
                        <NavLink to="/link1" activeClassName="frame__demo--current" className="frame__demo">
                            LINK1
                        </NavLink>
                        <NavLink to="/link2" activeClassName="frame__demo--current" className="frame__demo">
                            LINK2
                        </NavLink>
                        <NavLink to="/link3" activeClassName="frame__demo--current" className="frame__demo">
                            LINK3
                        </NavLink>
                    </div>
                </div> */}
                <div className='content'>
                    <Switch>
                        <Route exact path='/'>
                            <App scene={1} />
                        </Route>
                    </Switch>
                </div>
            </main>
        </Router>
    );
}

render(<Body />, document.getElementById('root'));
